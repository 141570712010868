import { applyMiddleware, compose, createStore } from 'redux';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import history from './history';
import createRootReducer from './reducers'
import thunkMiddleware from 'redux-thunk'
import { api } from "../middleware/api";

const initialState = {};

const store = createStore(
    createRootReducer(history),
    initialState,
    compose(
        applyMiddleware(
            createRouterMiddleware(history),
            thunkMiddleware,
            api
        ),
    )
);

export default store;
