import { useCallback, useEffect, useState } from "react";
import Api from "../../core/Api";
import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer } from "@mui/x-data-grid";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    TextField
} from "@mui/material";
import { cloneDeep } from "lodash";
import {
    Add,
    Cancel,
    Delete,
    Edit,
    ImportExport,
    Login,
    MoreVert,
    OpenInNew,
    PersonAdd,
    Replay,
    Save,
    SettingsBackupRestore,
    Sync
} from "@mui/icons-material";
import { v1 } from "uuid";
import DeleteDialog from "../Utils/DeleteDialog";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";

const defaultColumns = [
    {
        field: 'id', headerName: 'ID', flex: 1, valueFormatter: ({value}) => {
            if (value?.startsWith('temp_')) {
                return 'New record';
            }
            return value;
        }
    },
    {
        field: 'domain',
        headerName: 'Domain',
        editable: true,
        flex: 1,
        renderCell: (params) => {
            return <>
                {params.value}
                <IconButton
                    size='small' color='primary' target='_blank' component='a'
                    href={'https://' + params.value}><OpenInNew/>
                </IconButton>
            </>
        }
    },
    {
        field: 'user',
        headerName: 'User',
        editable: true,
        flex: 1,
        type: 'singleSelect',
        valueOptions: [],
        valueFormatter: ({value, field, api}) => {
            const colDef = api.getColumn(field);
            const option = colDef.valueOptions.find(
                ({value: optionValue}) => value === optionValue
            );
            return option?.label || value;
        }
    },
    {
        field: 'version',
        headerName: 'Version',
        editable: true,
        flex: 1,
        type: 'singleSelect',
        valueOptions: ['', 'starter', 'extended', 'pro'],
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 240,
        cellClassName: 'actions',
    },
]

function EditToolbar(props) {
    const {setSystems, setRowModesModel, fetchData} = props;

    const handleClick = () => {
        const id = 'temp_' + v1()
        setSystems((oldRows) => [{id, domain: '', user: '', isNew: true}, ...oldRows]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'domain'},
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<Add/>} onClick={handleClick}>
                Add record
            </Button>
            <Box sx={{flexGrow: 1}}/>
            <IconButton onClick={fetchData}><Replay/></IconButton>
        </GridToolbarContainer>
    );
}

const SystemsList = () => {
    const [systems, setSystems] = useState([]);
    const [users, setUsers] = useState([]);
    const [systemsLoading, setSystemsLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [usersLoading, setUsersLoading] = useState(true);
    const [rowModesModel, setRowModesModel] = useState({});
    const [deleteId, setDeleteId] = useState(null);
    const [deleting, setDeleting] = useState(false);

    const [syncId, setSyncId] = useState(null);
    const [syncing, setSyncing] = useState(false);

    const [menu, setMenu] = useState(null);

    const [addUserData, setAddUserData] = useState(null);
    const [addingUser, setAddingUser] = useState(false);

    const [migrationData, setMigrationData] = useState(null);
    const [migrating, setMigrating] = useState(false);

    const [purgeId, setPurgeId] = useState(null);
    const [purging, setPurging] = useState(false);

    const handleDelete = (id) => {
        setDeleting(true);

        Api.fetch({
            endpoint: 'systems/' + id,
            method: 'DELETE'
        }).then(() => {
            setSystems(systems.filter((row) => row.id !== id));
            setDeleteId(null);
        }, () => {
        }).then(() => setDeleting(false));
    }

    const fetchSystems = useCallback(() => {
        setSystemsLoading(true);
        Api.fetch({
            endpoint: 'systems',
            method: 'GET',
        }).then(res => setSystems(res.response)).then(() => setSystemsLoading(false));
    }, []);

    const fetchUsers = useCallback(() => {
        setUsersLoading(true);
        Api.fetch({
            endpoint: 'user',
            method: 'GET',
        }).then(res => setUsers(res.response)).then(() => setUsersLoading(false));
    }, []);

    const fetchData = useCallback(() => {
        fetchSystems();
        fetchUsers();
    }, [fetchSystems, fetchUsers]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const processRowUpdate = useCallback((newRow) => {
        setSaving(true);
        const updatedRow = {...newRow, isNew: false};

        Api.fetch({
            endpoint: newRow.isNew ? 'systems' : 'systems/' + newRow.id,
            method: newRow.isNew ? 'POST' : 'PUT',
            body: {
                domain: newRow.domain,
                user: newRow.user,
                version: newRow.version,
            }
        }).then(res => setSystems(systems.map((row) => (row.id === newRow.id ? res.response : row))), () => {
        }).then(() => setSaving(false));

        setSystems(systems.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    }, [systems]);

    const columns = cloneDeep(defaultColumns)
    columns[2].valueOptions = [{value: '', label: '-'}, ...users.map((user) => {
        const sub = user.Attributes.find((attr) => attr.Name === 'sub').Value || '';
        const email = user.Attributes.find((attribute) => attribute.Name === 'email')?.Value || '';
        const givenName = user.Attributes.find((attribute) => attribute.Name === 'given_name')?.Value || '';
        const familyName = user.Attributes.find((attribute) => attribute.Name === 'family_name')?.Value || '';
        return {
            value: sub,
            label: `${givenName} ${familyName} (${email})`
        }
    })];

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = systems.find((row) => row.id === id);
        if (editedRow.isNew) {
            setSystems(systems.filter((row) => row.id !== id));
        }
    };

    const login = (systemId) => {
        Api.fetch({
            endpoint: 'systems/' + systemId + '/login',
            method: 'POST',
        }).then(res => {
            const r = res.response
            window.open(`${r.redirectDomain}/login-callback#access_token=${r.token.accessToken}&refresh_token=${r.token.refreshToken}&id_token=${r.token.idToken}`, '_blank').focus();
        }, () => {
        })
    }

    columns[4].getActions = ({id}) => {
        let actions = [
            <GridActionsCellItem
                icon={<Edit/>}
                label="Edit"
                className="textPrimary"
                color="inherit"
                onClick={() => setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}})}
            />,
            <GridActionsCellItem
                icon={<PersonAdd/>}
                label="Add User"
                className="textPrimary"
                color="inherit"
                onClick={() => setAddUserData({
                    systemId: id,
                    givenName: '',
                    familyName: '',
                    email: '',
                })}
            />,
            <GridActionsCellItem
                icon={<Login/>}
                label="Login"
                className="textPrimary"
                color="inherit"
                onClick={() => login(id)}
            />,
            <GridActionsCellItem
                icon={<MoreVert/>}
                label={'More'}
                className="textPrimary"
                color="inherit"
                onClick={(e) => setMenu({
                    anchor: e.currentTarget,
                    id: id
                })}>
            </GridActionsCellItem>
        ];

        if (rowModesModel[id]?.mode === GridRowModes.Edit) {
            actions = [
                <GridActionsCellItem
                    icon={<Save/>}
                    label="Save"
                    color="inherit"
                    onClick={() => setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}})}
                />,
                <GridActionsCellItem
                    icon={<Cancel/>}
                    label="Cancel"
                    className="textPrimary"
                    color="inherit"
                    onClick={handleCancelClick(id)}
                />,
            ];
        }

        return actions;
    };

    const createNewUser = () => {
        setAddingUser(true);

        Api.fetch({
            endpoint: 'systems/' + addUserData.systemId + '/user',
            method: 'POST',
            body: {
                givenName: addUserData.givenName,
                familyName: addUserData.familyName,
                email: addUserData.email,
            }
        }).then(() => setAddUserData(null), () => {
        }).then(() => setAddingUser(false));
    }

    const startMigration = () => {
        setMigrating(true);
        Api.fetch({
            endpoint: 'systems/' + migrationData.systemId + '/migrate',
            method: 'POST',
            body: {
                domain: 'https://' + migrationData.domain,
                oldSystemId: migrationData.oldSystemId,
            }
        }).then(() => setMigrationData(null), () => {
        }).then(() => setMigrating(false));
    }

    const purgeSystem = () => {
        setPurging(true);

        Api.fetch({
            endpoint: 'systems/' + purgeId + '/purge',
            method: 'POST',
        }).then(() => setPurgeId(null), () => {
        }).then(() => setPurging(false));
    }

    const syncSystem = () => {
        setSyncing(true);

        Api.fetch({
            endpoint: 'systems/' + syncId + '/sync-elasticsearch',
            method: 'POST',
        }).then(() => setSyncId(null), () => {
        }).then(() => setSyncing(false));
    }

    return <>
        <Paper sx={{m: 2, height: 'calc(100% - 96px)'}}>
            <DataGrid
                loading={systemsLoading || usersLoading || saving}
                rows={systems}
                columns={columns}
                pageSize={25}
                editMode="row"
                rowsPerPageOptions={[25]}
                checkboxSelection
                processRowUpdate={processRowUpdate}
                rowModesModel={rowModesModel}
                onRowEditStart={(params, event) => event.defaultMuiPrevented = true}
                onRowEditStop={(params, event) => event.defaultMuiPrevented = true}
                disableSelectionOnClick
                experimentalFeatures={{newEditingApi: true}}
                components={{
                    Toolbar: EditToolbar,
                }}
                componentsProps={{
                    toolbar: {setSystems, setRowModesModel, fetchData},
                }}
            />
        </Paper>
        <Menu onClose={() => setMenu(null)} open={Boolean(menu)} anchorEl={menu?.anchor}>
            <MenuItem onClick={() => {
                setSyncId(menu.id);
                setMenu(null);
            }}>
                <ListItemIcon>
                    <Sync fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Sync Elasticsearch</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
                setMigrationData({
                    systemId: menu?.id,
                    oldSystemId: '',
                    domain: '',
                });
                setMenu(null);
            }}>
                <ListItemIcon>
                    <ImportExport fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Migrate</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
                setDeleteId(menu.id);
                setMenu(null);
            }}>
                <ListItemIcon>
                    <Delete fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
                setPurgeId(menu.id);
                setMenu(null);
            }}>
                <ListItemIcon>
                    <SettingsBackupRestore fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Purge</ListItemText>
            </MenuItem>
        </Menu>
        <DeleteDialog onDelete={() => handleDelete(deleteId)} isDeleting={deleting} title={'Delete System'}
                      handleClose={() => setDeleteId(null)}
                      description={`Are you sure you want to delete system ${deleteId}?`}
                      open={Boolean(deleteId)}/>
        <Dialog open={Boolean(addUserData)} onClose={() => addingUser ? null : setAddUserData(null)}>
            <DialogTitle>Add User to system</DialogTitle>
            <DialogContent>
                <TextField
                    disabled={addingUser}
                    margin="dense"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={addUserData?.email}
                    onChange={(e) => setAddUserData({...addUserData, email: e.target.value})}
                />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            disabled={addingUser}
                            margin="dense"
                            label="Given name"
                            type="text"
                            fullWidth
                            value={addUserData?.givenName}
                            onChange={(e) => setAddUserData({...addUserData, givenName: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={addingUser}
                            margin="dense"
                            label="Family name"
                            type="text"
                            fullWidth
                            value={addUserData?.familyName}
                            onChange={(e) => setAddUserData({...addUserData, familyName: e.target.value})}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={addingUser}
                        onClick={() => setAddUserData(null)}>Cancel</Button>
                <LoadingButton onClick={createNewUser}
                               loading={addingUser}
                               loadingPosition="start"
                               startIcon={<PersonAdd/>}
                               variant="contained"
                               color='primary'>Create</LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog open={Boolean(migrationData)} onClose={() => migrating ? null : setMigrationData(null)}>
            <DialogTitle>Migrate system</DialogTitle>
            <DialogContent>
                <TextField
                    disabled={migrating}
                    margin="dense"
                    label="Domain"
                    fullWidth
                    value={migrationData?.domain}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                    }}
                    onChange={(e) => setMigrationData({...migrationData, domain: e.target.value})}
                />
                <TextField
                    disabled={migrating}
                    margin="dense"
                    label="Old system id"
                    fullWidth
                    value={migrationData?.oldSystemId}
                    onChange={(e) => setMigrationData({...migrationData, oldSystemId: e.target.value})}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={migrating}
                        onClick={() => setMigrationData(null)}>Cancel</Button>
                <LoadingButton onClick={startMigration}
                               loading={migrating}
                               loadingPosition="start"
                               startIcon={<ImportExport/>}
                               variant="contained"
                               color='primary'>Start Migration</LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog
            disableEscapeKeyDown
            open={Boolean(purgeId)}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    setPurgeId(null)
                }
            }}
        >
            <DialogTitle>Purge System</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are u sure you want to purge the system {purgeId}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={purging} onClick={() => setPurgeId(null)} autoFocus>
                    Cancel
                </Button>
                <LoadingButton
                    onClick={purgeSystem}
                    loading={purging}
                    loadingPosition="start"
                    startIcon={<DeleteIcon/>}
                    variant="contained"
                    color='error'
                >
                    Purge
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog
            disableEscapeKeyDown
            open={Boolean(syncId)}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    setSyncId(null)
                }
            }}
        >
            <DialogTitle>Sync Elasticsearch</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are u sure you want to sync the elasticsearch for system {syncId}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={syncing} onClick={() => setSyncId(null)} autoFocus>
                    Cancel
                </Button>
                <LoadingButton
                    onClick={syncSystem}
                    loading={syncing}
                    loadingPosition="start"
                    startIcon={<Sync/>}
                    variant="contained"
                    color='primary'
                >
                    Start Sync
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}

export default SystemsList;
