import React from 'react';
import SomethingWentWrong from "./SomethingWentWrong";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null, errorInfo: null};
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true, error: error, errorInfo: info});
        console.log(error)
    }

    render() {
        if (this.state.hasError) {
            return <SomethingWentWrong error={this.state.error} errorInfo={this.state.errorInfo}/>
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
