import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navigation from "../Navigation/Navigation";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import LoadingScreen from "../Utils/LoadingScreen";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { fetchUser } from "../../actions/userActions";
import SystemsList from "../Systems/List";

const MainElement = styled('main')(({theme}) => ({
    flexGrow: 1
}));

const Toolbar = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const Main = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUser())
    }, [dispatch])

    const user = useSelector((state) => state.user)

    if (!user.user) {
        const errorList = [];
        if (user.error) {
            errorList.push(user.error)
        }
        return <LoadingScreen errorList={errorList}
                              loading={user.isFetching}/>
    }

    return <React.Fragment>
        <Box sx={{display: 'flex', height: '100%'}}>
            <Navigation path={location.pathname}/>
            <MainElement>
                <Toolbar/>
                <Routes>
                    <Route path='/systems'element={<SystemsList />}/>
                    <Route exact path='/' element={<Navigate to="dashboard"/>}/>
                </Routes>
            </MainElement>
        </Box>
    </React.Fragment>
}

export default Main
