import React, { Suspense, useEffect, useState } from 'react';
import { createTheme, CssBaseline, IconButton, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { history, store } from '../../core';
import Snackbars from '../Snackbar/Snackbars';
import LoadingScreen from '../Utils/LoadingScreen';
import { useCookies } from 'react-cookie'
import { StyledEngineProvider } from '@mui/material/styles';
import { Route, Routes } from "react-router";
import { enUS } from '@mui/x-data-grid';
import { enUS as coreEnUS } from '@mui/material/locale';
import { Close } from "@mui/icons-material";
import ErrorBoundary from "./ErrorBoundary";
import LoginCallback from "../Utils/LoginCallback";
import LoginRedirect from "../Utils/LoginRedirect";
import moment from "moment";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import Main from "../Main/Main";

const createCustomTheme = (theme) => {
    return createTheme(
        {
            palette: {
                mode: theme === 'dark' ? 'dark' : 'light',
                background: {
                    default: theme === 'dark' ? '#121212' : '#f3f3f3'
                    //default: "#f5f5f5"
                },
                primary: {
                    main: '#3b7c7f',
                },
                secondary: {
                    main: '#944642',
                },
                action: {
                    selected: 'rgba(0,0,0,0.12)',
                    selectedOpacity: 0.12
                }
            }
        },
        enUS,
        coreEnUS,
    )
};

moment.locale('en');

const defaultTheme = createCustomTheme('light');

const App = (props) => {
    // add action to all snackbars
    const notistackRef = React.createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    const [stateTheme, setStateTheme] = useState('light')
    const [customTheme, setCustomTheme] = useState(defaultTheme)
    const [cookies, setCookie] = useCookies(['theme']);
    const {theme} = cookies
    useEffect(() => {
        let myTheme = theme
        if (!theme) {
            myTheme = 'light'
            setCookie('theme', myTheme)
        }
        setStateTheme(myTheme);
    }, [theme, setCookie, setStateTheme])

    useEffect(() => {
        setCustomTheme(createCustomTheme(stateTheme));
    }, [stateTheme])

    // customized
    const action = (key) => (<IconButton color="inherit" size='small' onClick={onClickDismiss(key)}>
        <Close fontSize='small'/>
    </IconButton>);
    return <ThemeProvider theme={customTheme}>
        <StyledEngineProvider injectFirst>
            <CssBaseline/>
            <ErrorBoundary>
                <Suspense fallback={<LoadingScreen loading={true}/>}>
                    <Provider store={store}>
                        <ReduxRouter history={history}>
                            <SnackbarProvider ref={notistackRef} maxSnack={3} action={action}
                                              anchorOrigin={{
                                                  vertical: 'bottom',
                                                  horizontal: 'right',
                                              }}
                                              autoHideDuration={6000}
                                              preventDuplicate={true}>
                                <Snackbars/>
                                <Routes>
                                    <Route path='/login-callback' element={<LoginCallback/>}/>
                                    <Route path='/login' element={<LoginRedirect/>}/>
                                    <Route path="/*" element={<Main/>}/>
                                </Routes>
                            </SnackbarProvider>
                        </ReduxRouter>
                    </Provider>
                </Suspense>
            </ErrorBoundary>
        </StyledEngineProvider>
    </ThemeProvider>
};


export default App;
