import { LOCATION_CHANGE } from 'react-router-redux';

export default function locationChangeReducer(state = [], action) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return [action.payload, ...state].slice(0, 20)
        default:
            return state;
    }
}
