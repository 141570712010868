import React, { useEffect } from 'react';
import * as qs from 'query-string';
import { push } from 'react-router-redux'
import { useDispatch } from 'react-redux';
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router";

const LoginCallback = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    useEffect(() => {
        const query = qs.parse(location.hash);

        const decoded = jwtDecode(query.id_token);

        localStorage.setItem('user_name', decoded.email)
        localStorage.setItem('access_token', query.access_token)
        localStorage.setItem('id_token', query.id_token)
        dispatch(push('/'))
    }, [dispatch, location.hash])

    return <div/>
}

export default LoginCallback

